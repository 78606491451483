<template>
  <div>
    <!-- <PageHeading title="Application Next Steps"></PageHeading> -->
    <PageHeader title="Documents Needed"></PageHeader>

    <v-card>
      <v-card-title class="text-h5">Thank you for using The Title Girl to register your vehicle!</v-card-title>
      <!-- <v-card-subtitle>
        <div class="ml-4 mt-4 text-body-1">Please watch for an email from The Title Girl that will provide a cost breakdown of fees.</div>
        <div class="ml-4 mt-4 text-body-1">
          We use the Bill of Sale you have provided to determine your sales tax and all associated fees which will be detailed in your cost
          breakdown e-mail.
        </div>
      </v-card-subtitle> -->

      <!-- <v-card-title class="mt-4 text-h5">Below is an example of each document that needs to be mailed to us</v-card-title> -->
      <v-card-subtitle class="ml-4 mb-4 text-body-1">Below is an example of each document that needs to be mailed to us.  Click on a document to see it bigger</v-card-subtitle>
      <!-- <v-card-subtitle class="ml-4 text-body-1">Click on a document to see it bigger (or both sides if applicable)</v-card-subtitle> -->
    </v-card>

    <v-row>
      <v-col cols="4" class="d-flex flex-column">
        <DocumentCard
          title="Motor Vehicle Purchase Agreement"
          docName="motor-vehicle-purchase-agreement"
          fileName="bill_of_sale.jpg"
          description="This document is sometimes called the 'Bill of Sale'.  It details all of the costs for your new vehicle."
        ></DocumentCard>
      </v-col>
      <v-col cols="4" class="d-flex flex-column">
          <DocumentCard
          title="Limited Power of Attorney (Form VTR-271)"
          docName="limited-power-of-attorney"
          fileName="texas_limited_power_of_attorney.jpg"
          description="Attached in email sent from The Title Girl"
        ></DocumentCard>
      </v-col>
      <v-col cols="4" class="d-flex flex-column">
        <DocumentCard
          title="Application for Texas Title (Form #130-U)"
          docName="application-for-title"
          fileName="texas_application_for_title.jpg"
          description="Sign as Applicant/Owner – use date stamped at bottom of Bill of Sale"
        ></DocumentCard>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="4" class="d-flex flex-column">
        <DocumentCard
          title="Odometer Disclosure (VTR-40)"
          docName="texas-odometer-disclosure"
          fileName="texas_odometer_disclosure.jpg"
          description="Sign as Buyer/Agent"
        ></DocumentCard>
      </v-col>
      <v-col cols="4" class="d-flex flex-column">
        <DocumentCard
          title="Original Texas Vehicle Inspection Report"
          docName="vehicle-inspection-report"
          fileName="texas_vehicle_inspection.jpg"
          description="Request from Tesla if not already provided"
        ></DocumentCard>
      </v-col>
      <v-col cols="4" class="d-flex flex-column">
        <DocumentCard
          title="Trade-In Annex"
          docName="trade-in-annex"
          fileName="trade_in_annex.jpg"
          description="This form may not be applicable to your situation"
        ></DocumentCard>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="d-flex flex-column">
         <DocumentCard
          title="Title or Manufacturer Certificate of Origin (MCO)"
          docName="certificate-of-origin"
          fileName="texas_title_front.jpg"
          description="Sign the back of the MCO as the Purchaser, at the bottom of the page above your typed name. If signing a Title, sign everywhere it lists Buyer. If unsure, please contact us at 214-997-6005 or email a copy of the title (front and back) to register@thetitlegirl.com. "
        ></DocumentCard>
      </v-col>
      <v-col cols="4" class="d-flex flex-column">
        <DocumentCard title="Drivers License" description="Copy the front and back for each purchaser"></DocumentCard>
      </v-col>
      <v-col cols="4" class="d-flex flex-column">
        <DocumentCard
          title="Insurance Card"
          description="Copy of your Vehicle Insurance Card showing Texas Liability coverage. Insurance card must list applicant's name (or business) as it reads on the Application for Texas Title, Tesla Model and VIN#. "
        ></DocumentCard>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="4" class="d-flex flex-column">
        <DocumentCard
          title="Payment"
          description="If you choose to make an ACH payment you can click on Pay Now in this portal to make that ACH payment. A cashier check/money order (if not choosing ACH) made payable to The Title Girl is the other option. We will pay the county for you. "
        ></DocumentCard>
      </v-col>
      <v-col cols="4" class="d-flex flex-column">
        <DocumentCard
          title="FedEx Label"
          description="You will get an E-Mail with a FedEx Label in it.  Please print this out and use it to ship us your documents."
        ></DocumentCard>
      </v-col>
    </v-row>

    <v-divider></v-divider>
    <section class="text-right pa-3 mt-8" style="width: 100%">
      <BackButton text="back"></BackButton>
    </section>
  </div>
</template>
<script>
export default {
  components: {
    PageHeader: () => import('@/components/app/PageHeader'),
    DocumentCard: () => import('./DocumentCard'),
    // RegistrationHeader: () => import('@/components/app/RegistrationHeader'),
  },
  created() {},
  computed: {
    app() {
      return this.$store.state.registration.app;
    },
  },
  methods: {},
};
</script>

<style scoped></style>
